var prefix = "/";

export function logError(obj: {}): Promise<Response> {
  return fetch(prefix + "weberr", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(obj),
  });
}
