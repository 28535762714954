class Node<T> {
  next: Node<T> | null = null;
  prev: Node<T> | null = null;
  callback: (notifier: T) => void;
  constructor(callback: (notifier: T) => void) {
    this.callback = callback;
  }
}

export class ChangeNotifier {
  #listeners: Node<typeof this> | null = null;
  #latestNode: Node<typeof this> | null = null;

  notifyListeners() {
    if (this.#listeners === null) return;
    for (let node = this.#listeners; node !== null; node = node.next) {
      node.callback(this);
    }
  }
  subscribe(fn: (notifier: typeof this) => void) {
    let thisNode = new Node(fn);
    if (this.#listeners === null) {
      this.#listeners = thisNode;
    } else {
      thisNode.prev = this.#latestNode;
      this.#latestNode.next = thisNode;
    }
    this.#latestNode = thisNode;
    fn(this as typeof this);
    return {
      unsubscribe: () => {
        if (thisNode.prev === null) {
          this.#listeners = thisNode.next;
        } else {
          thisNode.prev.next = thisNode.next;
        }
        if (thisNode.next === null) {
          this.#latestNode = thisNode.prev;
        } else {
          thisNode.next.prev = thisNode.prev;
        }
        thisNode = null;
      },
    };
  }
}

export type Observer<T> = T extends ChangeNotifier ? T : never;
