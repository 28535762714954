import { useEffect } from "react";
import type { Observer } from "./ChangeNotifier";
import useComponentRefresh from "./useComponentRefresh";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function observe<T>(subscribable: Observer<T>) {
  const forceUpdate = useComponentRefresh();

  useEffect(() => {
    if (subscribable && subscribable.subscribe) {
      const subscription = subscribable.subscribe(() => {
        // console.log("subscribable changed", subscribable.constructor.name);
        forceUpdate();
      });
      return () => {
        return subscription.unsubscribe();
      };
    }
  }, [subscribable]);
  return subscribable as T;
}
