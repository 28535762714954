import atob from "./atob.js";

function b64DecodeUnicode(str) {
  return decodeURIComponent(
    atob(str).replace(/(.)/g, (m, p) => {
      let code = p.charCodeAt(0).toString(16).toUpperCase();
      if (code.length < 2) {
        code = `0${code}`;
      }
      return `%${code}`;
    }),
  );
}

export function fromBase64URLDecodeToBase64(str) {
  let output = str.replace(/-/g, "+").replace(/_/g, "/");
  switch (output.length % 4) {
    case 0:
      break;
    case 2:
      output += "==";
      break;
    case 3:
      output += "=";
      break;
    default:
      throw new Error("base64 string is not of the correct length");
  }

  return output;
}

export default function (str) {
  const output = fromBase64URLDecodeToBase64(str);
  try {
    return b64DecodeUnicode(output);
  } catch (err) {
    return atob(output);
  }
}
