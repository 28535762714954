import * as React from "react";
import context from "../../context/ReactContext";
import { extend } from "lodash";

function isLeftClickEvent(event: React.MouseEvent<any>) {
  return event.button === 0;
}

function isModifiedEvent(event: React.MouseEvent<any>) {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

export interface Properties {
  state?: any;
  href: string;
  className?: string;
  replace?: boolean;

  target?: any;
  children: React.ReactNode;
  onClick?: (event: React.SyntheticEvent<any>) => void;
}
export interface State {}

export default function Link(
  sentProps: Properties,
): React.ReactElement<Properties> {
  var props: any = {};
  extend(props, sentProps);
  const newTo = context.application.createURLWithContext(props.href);
  const newHref = newTo.href.replace(newTo.origin, "");
  props.onClick = (event) => {
    let allowTransition = true;
    if (sentProps.onClick) sentProps.onClick(event);
    if (isModifiedEvent(event) || !isLeftClickEvent(event)) return;
    if (event.defaultPrevented === true) allowTransition = false;
    if (sentProps.target) {
      if (!allowTransition) event.preventDefault();
      return;
    }
    event.preventDefault();
    if (allowTransition) {
      const currentLocation = context.application.getCleanLocation();
      if (currentLocation.pathname === sentProps.href || sentProps.replace) {
        context.replace(sentProps.href, sentProps.state);
      } else {
        context.push(sentProps.href, sentProps.state);
      }
    }
  };
  props.href = newHref;
  return <a {...props}>{sentProps.children}</a>;
}
