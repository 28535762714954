"use strict";

import base64_url_decode from "./base64UrlDecode";

export function InvalidTokenError(message) {
  this.message = message;
}

InvalidTokenError.prototype = new Error();
InvalidTokenError.prototype.name = "InvalidTokenError";

const partsIDs = {
  0: "header",
  1: "body",
  2: "signature",
};

type Token<T> = {
  header: any;
  body: T;
  signature: any;
};

export default function validateToken<T>(token): Token<T> {
  if (typeof token !== "string") {
    throw new InvalidTokenError("Invalid token specified: must be a string");
  }

  var parts: Token<T> = {
    header: {},
    body: {} as T,
    signature: {},
  };

  var tokenParts = token.split(".");
  if (tokenParts.length !== 3) {
    throw new InvalidTokenError(
      "Invalid token specified: There should be 3 parts",
    );
  }

  for (let i = 0; i < 2; i++) {
    const part = tokenParts[i];
    if (typeof part !== "string") {
      throw new InvalidTokenError(
        `Invalid token specified: part ${i} should be a string`,
      );
    }
    try {
      var decoded = base64_url_decode(part);
    } catch (e) {
      throw new InvalidTokenError(
        `Invalid token specified: invalid base64 for part #${i} (${e.message})`,
      );
    }

    try {
      const parsedPart = JSON.parse(decoded);
      parts[partsIDs[i]] = parsedPart;
    } catch (e) {
      throw new InvalidTokenError(
        `Invalid token specified: invalid json for part #${i} (${e.message})`,
      );
    }
  }
  parts[partsIDs[2]] = tokenParts[2];
  return parts;
}
