import React, { useState, useEffect } from "react";
import UserCard from "./navigation/user-card";
import BackCard from "./navigation/back-card";
import NotificationsCard from "./navigation/notifications-card";
// import TasksCard from "./navigation/tasks-card";
import sitetranslations from "./SiteTranslations";
import { observe } from "../../modules/customstate/react_observe";

export default function CustomNav() {
  observe(sitetranslations);
  useEffect(() => {
    sitetranslations.init();
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  }, []);
  if (sitetranslations.loading) {
    return null;
  }
  // <TasksCard />
  return (
    <>
      <NotificationsCard />
      <UserCard />
      <BackCard />
    </>
  );
}
