import React from "react";
import feather from "feather-icons";
import classNames from "classnames";

interface FeatherIconProps {
  icon: string;
  className?: string;
  options?: any;
}

export default class FeatherIcon extends React.PureComponent<
  FeatherIconProps,
  {}
> {
  static defaultProps = {
    className: "",
  };
  render() {
    let { icon } = this.props;
    let featherIcon = feather.icons[icon];
    if (!featherIcon) {
      console.error(`Icon ${icon} doesnt exist`);
      return <></>;
    }

    let dangerousIconHTML = {
      __html: feather.icons[icon].toSvg({ ...this.props.options }),
    };
    const iconClassNames = classNames("fa-icon", this.props.className);
    return (
      <span
        className={iconClassNames}
        dangerouslySetInnerHTML={dangerousIconHTML}
      />
    );
  }
}
