/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 5.26.1
 * source: Entities.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as pb_1 from "google-protobuf";
export enum EntityStatus {
    DELETED = 0,
    INACTIVE = 1,
    ACTIVE = 10
}
export enum EntityType {
    PERSON = 0,
    ORGANIZATION = 1,
    APPLICATION = 2
}
export enum SimpleAnswer {
    NO = 0,
    YES = 1
}
export class Entity extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        id?: string;
        name?: string;
        status?: EntityStatus;
        type?: EntityType;
        created_at?: number;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("id" in data && data.id != undefined) {
                this.id = data.id;
            }
            if ("name" in data && data.name != undefined) {
                this.name = data.name;
            }
            if ("status" in data && data.status != undefined) {
                this.status = data.status;
            }
            if ("type" in data && data.type != undefined) {
                this.type = data.type;
            }
            if ("created_at" in data && data.created_at != undefined) {
                this.created_at = data.created_at;
            }
        }
    }
    get id() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set id(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get name() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set name(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get status() {
        return pb_1.Message.getFieldWithDefault(this, 3, EntityStatus.DELETED) as EntityStatus;
    }
    set status(value: EntityStatus) {
        pb_1.Message.setField(this, 3, value);
    }
    get type() {
        return pb_1.Message.getFieldWithDefault(this, 4, EntityType.PERSON) as EntityType;
    }
    set type(value: EntityType) {
        pb_1.Message.setField(this, 4, value);
    }
    get created_at() {
        return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
    }
    set created_at(value: number) {
        pb_1.Message.setField(this, 5, value);
    }
    static fromObject(data: {
        id?: string;
        name?: string;
        status?: EntityStatus;
        type?: EntityType;
        created_at?: number;
    }): Entity {
        const message = new Entity({});
        if (data.id != null) {
            message.id = data.id;
        }
        if (data.name != null) {
            message.name = data.name;
        }
        if (data.status != null) {
            message.status = data.status;
        }
        if (data.type != null) {
            message.type = data.type;
        }
        if (data.created_at != null) {
            message.created_at = data.created_at;
        }
        return message;
    }
    toObject() {
        const data: {
            id?: string;
            name?: string;
            status?: EntityStatus;
            type?: EntityType;
            created_at?: number;
        } = {};
        if (this.id != null) {
            data.id = this.id;
        }
        if (this.name != null) {
            data.name = this.name;
        }
        if (this.status != null) {
            data.status = this.status;
        }
        if (this.type != null) {
            data.type = this.type;
        }
        if (this.created_at != null) {
            data.created_at = this.created_at;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.id.length)
            writer.writeString(1, this.id);
        if (this.name.length)
            writer.writeString(2, this.name);
        if (this.status != EntityStatus.DELETED)
            writer.writeEnum(3, this.status);
        if (this.type != EntityType.PERSON)
            writer.writeEnum(4, this.type);
        if (this.created_at != 0)
            writer.writeInt64(5, this.created_at);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Entity {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Entity();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.id = reader.readString();
                    break;
                case 2:
                    message.name = reader.readString();
                    break;
                case 3:
                    message.status = reader.readEnum();
                    break;
                case 4:
                    message.type = reader.readEnum();
                    break;
                case 5:
                    message.created_at = reader.readInt64();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): Entity {
        return Entity.deserialize(bytes);
    }
}
export class EntityProperty extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        entity_id?: string;
        scope?: string;
        name?: string;
        value?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("entity_id" in data && data.entity_id != undefined) {
                this.entity_id = data.entity_id;
            }
            if ("scope" in data && data.scope != undefined) {
                this.scope = data.scope;
            }
            if ("name" in data && data.name != undefined) {
                this.name = data.name;
            }
            if ("value" in data && data.value != undefined) {
                this.value = data.value;
            }
        }
    }
    get entity_id() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set entity_id(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get scope() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set scope(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get name() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set name(value: string) {
        pb_1.Message.setField(this, 3, value);
    }
    get value() {
        return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
    }
    set value(value: string) {
        pb_1.Message.setField(this, 4, value);
    }
    static fromObject(data: {
        entity_id?: string;
        scope?: string;
        name?: string;
        value?: string;
    }): EntityProperty {
        const message = new EntityProperty({});
        if (data.entity_id != null) {
            message.entity_id = data.entity_id;
        }
        if (data.scope != null) {
            message.scope = data.scope;
        }
        if (data.name != null) {
            message.name = data.name;
        }
        if (data.value != null) {
            message.value = data.value;
        }
        return message;
    }
    toObject() {
        const data: {
            entity_id?: string;
            scope?: string;
            name?: string;
            value?: string;
        } = {};
        if (this.entity_id != null) {
            data.entity_id = this.entity_id;
        }
        if (this.scope != null) {
            data.scope = this.scope;
        }
        if (this.name != null) {
            data.name = this.name;
        }
        if (this.value != null) {
            data.value = this.value;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.entity_id.length)
            writer.writeString(1, this.entity_id);
        if (this.scope.length)
            writer.writeString(2, this.scope);
        if (this.name.length)
            writer.writeString(3, this.name);
        if (this.value.length)
            writer.writeString(4, this.value);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): EntityProperty {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new EntityProperty();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.entity_id = reader.readString();
                    break;
                case 2:
                    message.scope = reader.readString();
                    break;
                case 3:
                    message.name = reader.readString();
                    break;
                case 4:
                    message.value = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): EntityProperty {
        return EntityProperty.deserialize(bytes);
    }
}
export class Person extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        entity_id?: string;
        shortname?: string;
        birthday?: number;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("entity_id" in data && data.entity_id != undefined) {
                this.entity_id = data.entity_id;
            }
            if ("shortname" in data && data.shortname != undefined) {
                this.shortname = data.shortname;
            }
            if ("birthday" in data && data.birthday != undefined) {
                this.birthday = data.birthday;
            }
        }
    }
    get entity_id() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set entity_id(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get shortname() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set shortname(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get birthday() {
        return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
    }
    set birthday(value: number) {
        pb_1.Message.setField(this, 3, value);
    }
    static fromObject(data: {
        entity_id?: string;
        shortname?: string;
        birthday?: number;
    }): Person {
        const message = new Person({});
        if (data.entity_id != null) {
            message.entity_id = data.entity_id;
        }
        if (data.shortname != null) {
            message.shortname = data.shortname;
        }
        if (data.birthday != null) {
            message.birthday = data.birthday;
        }
        return message;
    }
    toObject() {
        const data: {
            entity_id?: string;
            shortname?: string;
            birthday?: number;
        } = {};
        if (this.entity_id != null) {
            data.entity_id = this.entity_id;
        }
        if (this.shortname != null) {
            data.shortname = this.shortname;
        }
        if (this.birthday != null) {
            data.birthday = this.birthday;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.entity_id.length)
            writer.writeString(1, this.entity_id);
        if (this.shortname.length)
            writer.writeString(2, this.shortname);
        if (this.birthday != 0)
            writer.writeInt64(3, this.birthday);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Person {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Person();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.entity_id = reader.readString();
                    break;
                case 2:
                    message.shortname = reader.readString();
                    break;
                case 3:
                    message.birthday = reader.readInt64();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): Person {
        return Person.deserialize(bytes);
    }
}
export class WebUser extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        entity_id?: string;
        password?: string;
        password_encoded?: string;
        password_changed_on?: number;
        lang?: string;
        otp_type?: number;
        otp_secret?: string;
        otp_added_at?: number;
        webauthn_certificates_added?: boolean;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("entity_id" in data && data.entity_id != undefined) {
                this.entity_id = data.entity_id;
            }
            if ("password" in data && data.password != undefined) {
                this.password = data.password;
            }
            if ("password_encoded" in data && data.password_encoded != undefined) {
                this.password_encoded = data.password_encoded;
            }
            if ("password_changed_on" in data && data.password_changed_on != undefined) {
                this.password_changed_on = data.password_changed_on;
            }
            if ("lang" in data && data.lang != undefined) {
                this.lang = data.lang;
            }
            if ("otp_type" in data && data.otp_type != undefined) {
                this.otp_type = data.otp_type;
            }
            if ("otp_secret" in data && data.otp_secret != undefined) {
                this.otp_secret = data.otp_secret;
            }
            if ("otp_added_at" in data && data.otp_added_at != undefined) {
                this.otp_added_at = data.otp_added_at;
            }
            if ("webauthn_certificates_added" in data && data.webauthn_certificates_added != undefined) {
                this.webauthn_certificates_added = data.webauthn_certificates_added;
            }
        }
    }
    get entity_id() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set entity_id(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get password() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set password(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get password_encoded() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set password_encoded(value: string) {
        pb_1.Message.setField(this, 3, value);
    }
    get password_changed_on() {
        return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
    }
    set password_changed_on(value: number) {
        pb_1.Message.setField(this, 4, value);
    }
    get lang() {
        return pb_1.Message.getFieldWithDefault(this, 5, "") as string;
    }
    set lang(value: string) {
        pb_1.Message.setField(this, 5, value);
    }
    get otp_type() {
        return pb_1.Message.getFieldWithDefault(this, 6, 0) as number;
    }
    set otp_type(value: number) {
        pb_1.Message.setField(this, 6, value);
    }
    get otp_secret() {
        return pb_1.Message.getFieldWithDefault(this, 7, "") as string;
    }
    set otp_secret(value: string) {
        pb_1.Message.setField(this, 7, value);
    }
    get otp_added_at() {
        return pb_1.Message.getFieldWithDefault(this, 8, 0) as number;
    }
    set otp_added_at(value: number) {
        pb_1.Message.setField(this, 8, value);
    }
    get webauthn_certificates_added() {
        return pb_1.Message.getFieldWithDefault(this, 9, false) as boolean;
    }
    set webauthn_certificates_added(value: boolean) {
        pb_1.Message.setField(this, 9, value);
    }
    static fromObject(data: {
        entity_id?: string;
        password?: string;
        password_encoded?: string;
        password_changed_on?: number;
        lang?: string;
        otp_type?: number;
        otp_secret?: string;
        otp_added_at?: number;
        webauthn_certificates_added?: boolean;
    }): WebUser {
        const message = new WebUser({});
        if (data.entity_id != null) {
            message.entity_id = data.entity_id;
        }
        if (data.password != null) {
            message.password = data.password;
        }
        if (data.password_encoded != null) {
            message.password_encoded = data.password_encoded;
        }
        if (data.password_changed_on != null) {
            message.password_changed_on = data.password_changed_on;
        }
        if (data.lang != null) {
            message.lang = data.lang;
        }
        if (data.otp_type != null) {
            message.otp_type = data.otp_type;
        }
        if (data.otp_secret != null) {
            message.otp_secret = data.otp_secret;
        }
        if (data.otp_added_at != null) {
            message.otp_added_at = data.otp_added_at;
        }
        if (data.webauthn_certificates_added != null) {
            message.webauthn_certificates_added = data.webauthn_certificates_added;
        }
        return message;
    }
    toObject() {
        const data: {
            entity_id?: string;
            password?: string;
            password_encoded?: string;
            password_changed_on?: number;
            lang?: string;
            otp_type?: number;
            otp_secret?: string;
            otp_added_at?: number;
            webauthn_certificates_added?: boolean;
        } = {};
        if (this.entity_id != null) {
            data.entity_id = this.entity_id;
        }
        if (this.password != null) {
            data.password = this.password;
        }
        if (this.password_encoded != null) {
            data.password_encoded = this.password_encoded;
        }
        if (this.password_changed_on != null) {
            data.password_changed_on = this.password_changed_on;
        }
        if (this.lang != null) {
            data.lang = this.lang;
        }
        if (this.otp_type != null) {
            data.otp_type = this.otp_type;
        }
        if (this.otp_secret != null) {
            data.otp_secret = this.otp_secret;
        }
        if (this.otp_added_at != null) {
            data.otp_added_at = this.otp_added_at;
        }
        if (this.webauthn_certificates_added != null) {
            data.webauthn_certificates_added = this.webauthn_certificates_added;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.entity_id.length)
            writer.writeString(1, this.entity_id);
        if (this.password.length)
            writer.writeString(2, this.password);
        if (this.password_encoded.length)
            writer.writeString(3, this.password_encoded);
        if (this.password_changed_on != 0)
            writer.writeInt64(4, this.password_changed_on);
        if (this.lang.length)
            writer.writeString(5, this.lang);
        if (this.otp_type != 0)
            writer.writeInt64(6, this.otp_type);
        if (this.otp_secret.length)
            writer.writeString(7, this.otp_secret);
        if (this.otp_added_at != 0)
            writer.writeInt64(8, this.otp_added_at);
        if (this.webauthn_certificates_added != false)
            writer.writeBool(9, this.webauthn_certificates_added);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): WebUser {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new WebUser();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.entity_id = reader.readString();
                    break;
                case 2:
                    message.password = reader.readString();
                    break;
                case 3:
                    message.password_encoded = reader.readString();
                    break;
                case 4:
                    message.password_changed_on = reader.readInt64();
                    break;
                case 5:
                    message.lang = reader.readString();
                    break;
                case 6:
                    message.otp_type = reader.readInt64();
                    break;
                case 7:
                    message.otp_secret = reader.readString();
                    break;
                case 8:
                    message.otp_added_at = reader.readInt64();
                    break;
                case 9:
                    message.webauthn_certificates_added = reader.readBool();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): WebUser {
        return WebUser.deserialize(bytes);
    }
}
export class Email extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        id?: number;
        entity_id?: string;
        email?: string;
        valid?: boolean;
        validated_at?: number;
        primary?: boolean;
        primary_at?: number;
        created_at?: number;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("id" in data && data.id != undefined) {
                this.id = data.id;
            }
            if ("entity_id" in data && data.entity_id != undefined) {
                this.entity_id = data.entity_id;
            }
            if ("email" in data && data.email != undefined) {
                this.email = data.email;
            }
            if ("valid" in data && data.valid != undefined) {
                this.valid = data.valid;
            }
            if ("validated_at" in data && data.validated_at != undefined) {
                this.validated_at = data.validated_at;
            }
            if ("primary" in data && data.primary != undefined) {
                this.primary = data.primary;
            }
            if ("primary_at" in data && data.primary_at != undefined) {
                this.primary_at = data.primary_at;
            }
            if ("created_at" in data && data.created_at != undefined) {
                this.created_at = data.created_at;
            }
        }
    }
    get id() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set id(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get entity_id() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set entity_id(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get email() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set email(value: string) {
        pb_1.Message.setField(this, 3, value);
    }
    get valid() {
        return pb_1.Message.getFieldWithDefault(this, 4, false) as boolean;
    }
    set valid(value: boolean) {
        pb_1.Message.setField(this, 4, value);
    }
    get validated_at() {
        return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
    }
    set validated_at(value: number) {
        pb_1.Message.setField(this, 5, value);
    }
    get primary() {
        return pb_1.Message.getFieldWithDefault(this, 6, false) as boolean;
    }
    set primary(value: boolean) {
        pb_1.Message.setField(this, 6, value);
    }
    get primary_at() {
        return pb_1.Message.getFieldWithDefault(this, 7, 0) as number;
    }
    set primary_at(value: number) {
        pb_1.Message.setField(this, 7, value);
    }
    get created_at() {
        return pb_1.Message.getFieldWithDefault(this, 8, 0) as number;
    }
    set created_at(value: number) {
        pb_1.Message.setField(this, 8, value);
    }
    static fromObject(data: {
        id?: number;
        entity_id?: string;
        email?: string;
        valid?: boolean;
        validated_at?: number;
        primary?: boolean;
        primary_at?: number;
        created_at?: number;
    }): Email {
        const message = new Email({});
        if (data.id != null) {
            message.id = data.id;
        }
        if (data.entity_id != null) {
            message.entity_id = data.entity_id;
        }
        if (data.email != null) {
            message.email = data.email;
        }
        if (data.valid != null) {
            message.valid = data.valid;
        }
        if (data.validated_at != null) {
            message.validated_at = data.validated_at;
        }
        if (data.primary != null) {
            message.primary = data.primary;
        }
        if (data.primary_at != null) {
            message.primary_at = data.primary_at;
        }
        if (data.created_at != null) {
            message.created_at = data.created_at;
        }
        return message;
    }
    toObject() {
        const data: {
            id?: number;
            entity_id?: string;
            email?: string;
            valid?: boolean;
            validated_at?: number;
            primary?: boolean;
            primary_at?: number;
            created_at?: number;
        } = {};
        if (this.id != null) {
            data.id = this.id;
        }
        if (this.entity_id != null) {
            data.entity_id = this.entity_id;
        }
        if (this.email != null) {
            data.email = this.email;
        }
        if (this.valid != null) {
            data.valid = this.valid;
        }
        if (this.validated_at != null) {
            data.validated_at = this.validated_at;
        }
        if (this.primary != null) {
            data.primary = this.primary;
        }
        if (this.primary_at != null) {
            data.primary_at = this.primary_at;
        }
        if (this.created_at != null) {
            data.created_at = this.created_at;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.id != 0)
            writer.writeInt64(1, this.id);
        if (this.entity_id.length)
            writer.writeString(2, this.entity_id);
        if (this.email.length)
            writer.writeString(3, this.email);
        if (this.valid != false)
            writer.writeBool(4, this.valid);
        if (this.validated_at != 0)
            writer.writeInt64(5, this.validated_at);
        if (this.primary != false)
            writer.writeBool(6, this.primary);
        if (this.primary_at != 0)
            writer.writeInt64(7, this.primary_at);
        if (this.created_at != 0)
            writer.writeInt64(8, this.created_at);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Email {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Email();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.id = reader.readInt64();
                    break;
                case 2:
                    message.entity_id = reader.readString();
                    break;
                case 3:
                    message.email = reader.readString();
                    break;
                case 4:
                    message.valid = reader.readBool();
                    break;
                case 5:
                    message.validated_at = reader.readInt64();
                    break;
                case 6:
                    message.primary = reader.readBool();
                    break;
                case 7:
                    message.primary_at = reader.readInt64();
                    break;
                case 8:
                    message.created_at = reader.readInt64();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): Email {
        return Email.deserialize(bytes);
    }
}
export class User extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        id?: string;
        entity?: Entity;
        person?: Person;
        webuser?: WebUser;
        emails?: Email[];
        primary_email?: Email;
        properties?: EntityProperty[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [5, 7], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("id" in data && data.id != undefined) {
                this.id = data.id;
            }
            if ("entity" in data && data.entity != undefined) {
                this.entity = data.entity;
            }
            if ("person" in data && data.person != undefined) {
                this.person = data.person;
            }
            if ("webuser" in data && data.webuser != undefined) {
                this.webuser = data.webuser;
            }
            if ("emails" in data && data.emails != undefined) {
                this.emails = data.emails;
            }
            if ("primary_email" in data && data.primary_email != undefined) {
                this.primary_email = data.primary_email;
            }
            if ("properties" in data && data.properties != undefined) {
                this.properties = data.properties;
            }
        }
    }
    get id() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set id(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get entity() {
        return pb_1.Message.getWrapperField(this, Entity, 2) as Entity;
    }
    set entity(value: Entity) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get has_entity() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get person() {
        return pb_1.Message.getWrapperField(this, Person, 3) as Person;
    }
    set person(value: Person) {
        pb_1.Message.setWrapperField(this, 3, value);
    }
    get has_person() {
        return pb_1.Message.getField(this, 3) != null;
    }
    get webuser() {
        return pb_1.Message.getWrapperField(this, WebUser, 4) as WebUser;
    }
    set webuser(value: WebUser) {
        pb_1.Message.setWrapperField(this, 4, value);
    }
    get has_webuser() {
        return pb_1.Message.getField(this, 4) != null;
    }
    get emails() {
        return pb_1.Message.getRepeatedWrapperField(this, Email, 5) as Email[];
    }
    set emails(value: Email[]) {
        pb_1.Message.setRepeatedWrapperField(this, 5, value);
    }
    get primary_email() {
        return pb_1.Message.getWrapperField(this, Email, 6) as Email;
    }
    set primary_email(value: Email) {
        pb_1.Message.setWrapperField(this, 6, value);
    }
    get has_primary_email() {
        return pb_1.Message.getField(this, 6) != null;
    }
    get properties() {
        return pb_1.Message.getRepeatedWrapperField(this, EntityProperty, 7) as EntityProperty[];
    }
    set properties(value: EntityProperty[]) {
        pb_1.Message.setRepeatedWrapperField(this, 7, value);
    }
    static fromObject(data: {
        id?: string;
        entity?: ReturnType<typeof Entity.prototype.toObject>;
        person?: ReturnType<typeof Person.prototype.toObject>;
        webuser?: ReturnType<typeof WebUser.prototype.toObject>;
        emails?: ReturnType<typeof Email.prototype.toObject>[];
        primary_email?: ReturnType<typeof Email.prototype.toObject>;
        properties?: ReturnType<typeof EntityProperty.prototype.toObject>[];
    }): User {
        const message = new User({});
        if (data.id != null) {
            message.id = data.id;
        }
        if (data.entity != null) {
            message.entity = Entity.fromObject(data.entity);
        }
        if (data.person != null) {
            message.person = Person.fromObject(data.person);
        }
        if (data.webuser != null) {
            message.webuser = WebUser.fromObject(data.webuser);
        }
        if (data.emails != null) {
            message.emails = data.emails.map(item => Email.fromObject(item));
        }
        if (data.primary_email != null) {
            message.primary_email = Email.fromObject(data.primary_email);
        }
        if (data.properties != null) {
            message.properties = data.properties.map(item => EntityProperty.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: {
            id?: string;
            entity?: ReturnType<typeof Entity.prototype.toObject>;
            person?: ReturnType<typeof Person.prototype.toObject>;
            webuser?: ReturnType<typeof WebUser.prototype.toObject>;
            emails?: ReturnType<typeof Email.prototype.toObject>[];
            primary_email?: ReturnType<typeof Email.prototype.toObject>;
            properties?: ReturnType<typeof EntityProperty.prototype.toObject>[];
        } = {};
        if (this.id != null) {
            data.id = this.id;
        }
        if (this.entity != null) {
            data.entity = this.entity.toObject();
        }
        if (this.person != null) {
            data.person = this.person.toObject();
        }
        if (this.webuser != null) {
            data.webuser = this.webuser.toObject();
        }
        if (this.emails != null) {
            data.emails = this.emails.map((item: Email) => item.toObject());
        }
        if (this.primary_email != null) {
            data.primary_email = this.primary_email.toObject();
        }
        if (this.properties != null) {
            data.properties = this.properties.map((item: EntityProperty) => item.toObject());
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.id.length)
            writer.writeString(1, this.id);
        if (this.has_entity)
            writer.writeMessage(2, this.entity, () => this.entity.serialize(writer));
        if (this.has_person)
            writer.writeMessage(3, this.person, () => this.person.serialize(writer));
        if (this.has_webuser)
            writer.writeMessage(4, this.webuser, () => this.webuser.serialize(writer));
        if (this.emails.length)
            writer.writeRepeatedMessage(5, this.emails, (item: Email) => item.serialize(writer));
        if (this.has_primary_email)
            writer.writeMessage(6, this.primary_email, () => this.primary_email.serialize(writer));
        if (this.properties.length)
            writer.writeRepeatedMessage(7, this.properties, (item: EntityProperty) => item.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): User {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new User();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.id = reader.readString();
                    break;
                case 2:
                    reader.readMessage(message.entity, () => message.entity = Entity.deserialize(reader));
                    break;
                case 3:
                    reader.readMessage(message.person, () => message.person = Person.deserialize(reader));
                    break;
                case 4:
                    reader.readMessage(message.webuser, () => message.webuser = WebUser.deserialize(reader));
                    break;
                case 5:
                    reader.readMessage(message.emails, () => pb_1.Message.addToRepeatedWrapperField(message, 5, Email.deserialize(reader), Email));
                    break;
                case 6:
                    reader.readMessage(message.primary_email, () => message.primary_email = Email.deserialize(reader));
                    break;
                case 7:
                    reader.readMessage(message.properties, () => pb_1.Message.addToRepeatedWrapperField(message, 7, EntityProperty.deserialize(reader), EntityProperty));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): User {
        return User.deserialize(bytes);
    }
}
export class Organization extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        id?: string;
        entity?: Entity;
        slug?: string;
        headquarters?: string;
        roles?: OrganizationRole[];
        properties?: EntityProperty[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [6, 7], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("id" in data && data.id != undefined) {
                this.id = data.id;
            }
            if ("entity" in data && data.entity != undefined) {
                this.entity = data.entity;
            }
            if ("slug" in data && data.slug != undefined) {
                this.slug = data.slug;
            }
            if ("headquarters" in data && data.headquarters != undefined) {
                this.headquarters = data.headquarters;
            }
            if ("roles" in data && data.roles != undefined) {
                this.roles = data.roles;
            }
            if ("properties" in data && data.properties != undefined) {
                this.properties = data.properties;
            }
        }
    }
    get id() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set id(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get entity() {
        return pb_1.Message.getWrapperField(this, Entity, 2) as Entity;
    }
    set entity(value: Entity) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get has_entity() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get slug() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set slug(value: string) {
        pb_1.Message.setField(this, 3, value);
    }
    get headquarters() {
        return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
    }
    set headquarters(value: string) {
        pb_1.Message.setField(this, 4, value);
    }
    get roles() {
        return pb_1.Message.getRepeatedWrapperField(this, OrganizationRole, 6) as OrganizationRole[];
    }
    set roles(value: OrganizationRole[]) {
        pb_1.Message.setRepeatedWrapperField(this, 6, value);
    }
    get properties() {
        return pb_1.Message.getRepeatedWrapperField(this, EntityProperty, 7) as EntityProperty[];
    }
    set properties(value: EntityProperty[]) {
        pb_1.Message.setRepeatedWrapperField(this, 7, value);
    }
    static fromObject(data: {
        id?: string;
        entity?: ReturnType<typeof Entity.prototype.toObject>;
        slug?: string;
        headquarters?: string;
        roles?: ReturnType<typeof OrganizationRole.prototype.toObject>[];
        properties?: ReturnType<typeof EntityProperty.prototype.toObject>[];
    }): Organization {
        const message = new Organization({});
        if (data.id != null) {
            message.id = data.id;
        }
        if (data.entity != null) {
            message.entity = Entity.fromObject(data.entity);
        }
        if (data.slug != null) {
            message.slug = data.slug;
        }
        if (data.headquarters != null) {
            message.headquarters = data.headquarters;
        }
        if (data.roles != null) {
            message.roles = data.roles.map(item => OrganizationRole.fromObject(item));
        }
        if (data.properties != null) {
            message.properties = data.properties.map(item => EntityProperty.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: {
            id?: string;
            entity?: ReturnType<typeof Entity.prototype.toObject>;
            slug?: string;
            headquarters?: string;
            roles?: ReturnType<typeof OrganizationRole.prototype.toObject>[];
            properties?: ReturnType<typeof EntityProperty.prototype.toObject>[];
        } = {};
        if (this.id != null) {
            data.id = this.id;
        }
        if (this.entity != null) {
            data.entity = this.entity.toObject();
        }
        if (this.slug != null) {
            data.slug = this.slug;
        }
        if (this.headquarters != null) {
            data.headquarters = this.headquarters;
        }
        if (this.roles != null) {
            data.roles = this.roles.map((item: OrganizationRole) => item.toObject());
        }
        if (this.properties != null) {
            data.properties = this.properties.map((item: EntityProperty) => item.toObject());
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.id.length)
            writer.writeString(1, this.id);
        if (this.has_entity)
            writer.writeMessage(2, this.entity, () => this.entity.serialize(writer));
        if (this.slug.length)
            writer.writeString(3, this.slug);
        if (this.headquarters.length)
            writer.writeString(4, this.headquarters);
        if (this.roles.length)
            writer.writeRepeatedMessage(6, this.roles, (item: OrganizationRole) => item.serialize(writer));
        if (this.properties.length)
            writer.writeRepeatedMessage(7, this.properties, (item: EntityProperty) => item.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Organization {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Organization();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.id = reader.readString();
                    break;
                case 2:
                    reader.readMessage(message.entity, () => message.entity = Entity.deserialize(reader));
                    break;
                case 3:
                    message.slug = reader.readString();
                    break;
                case 4:
                    message.headquarters = reader.readString();
                    break;
                case 6:
                    reader.readMessage(message.roles, () => pb_1.Message.addToRepeatedWrapperField(message, 6, OrganizationRole.deserialize(reader), OrganizationRole));
                    break;
                case 7:
                    reader.readMessage(message.properties, () => pb_1.Message.addToRepeatedWrapperField(message, 7, EntityProperty.deserialize(reader), EntityProperty));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): Organization {
        return Organization.deserialize(bytes);
    }
}
export class OrganizationRole extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        id?: string;
        organization_id?: string;
        name?: string;
        comment?: string;
        active?: boolean;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("id" in data && data.id != undefined) {
                this.id = data.id;
            }
            if ("organization_id" in data && data.organization_id != undefined) {
                this.organization_id = data.organization_id;
            }
            if ("name" in data && data.name != undefined) {
                this.name = data.name;
            }
            if ("comment" in data && data.comment != undefined) {
                this.comment = data.comment;
            }
            if ("active" in data && data.active != undefined) {
                this.active = data.active;
            }
        }
    }
    get id() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set id(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get organization_id() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set organization_id(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get name() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set name(value: string) {
        pb_1.Message.setField(this, 3, value);
    }
    get comment() {
        return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
    }
    set comment(value: string) {
        pb_1.Message.setField(this, 4, value);
    }
    get active() {
        return pb_1.Message.getFieldWithDefault(this, 5, false) as boolean;
    }
    set active(value: boolean) {
        pb_1.Message.setField(this, 5, value);
    }
    static fromObject(data: {
        id?: string;
        organization_id?: string;
        name?: string;
        comment?: string;
        active?: boolean;
    }): OrganizationRole {
        const message = new OrganizationRole({});
        if (data.id != null) {
            message.id = data.id;
        }
        if (data.organization_id != null) {
            message.organization_id = data.organization_id;
        }
        if (data.name != null) {
            message.name = data.name;
        }
        if (data.comment != null) {
            message.comment = data.comment;
        }
        if (data.active != null) {
            message.active = data.active;
        }
        return message;
    }
    toObject() {
        const data: {
            id?: string;
            organization_id?: string;
            name?: string;
            comment?: string;
            active?: boolean;
        } = {};
        if (this.id != null) {
            data.id = this.id;
        }
        if (this.organization_id != null) {
            data.organization_id = this.organization_id;
        }
        if (this.name != null) {
            data.name = this.name;
        }
        if (this.comment != null) {
            data.comment = this.comment;
        }
        if (this.active != null) {
            data.active = this.active;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.id.length)
            writer.writeString(1, this.id);
        if (this.organization_id.length)
            writer.writeString(2, this.organization_id);
        if (this.name.length)
            writer.writeString(3, this.name);
        if (this.comment.length)
            writer.writeString(4, this.comment);
        if (this.active != false)
            writer.writeBool(5, this.active);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): OrganizationRole {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new OrganizationRole();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.id = reader.readString();
                    break;
                case 2:
                    message.organization_id = reader.readString();
                    break;
                case 3:
                    message.name = reader.readString();
                    break;
                case 4:
                    message.comment = reader.readString();
                    break;
                case 5:
                    message.active = reader.readBool();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): OrganizationRole {
        return OrganizationRole.deserialize(bytes);
    }
}
export class RolePermissionMap extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        owner?: boolean;
        master?: boolean;
        operator?: boolean;
        undelete?: boolean;
        delete?: boolean;
        create?: boolean;
        edit?: boolean;
        view?: boolean;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("owner" in data && data.owner != undefined) {
                this.owner = data.owner;
            }
            if ("master" in data && data.master != undefined) {
                this.master = data.master;
            }
            if ("operator" in data && data.operator != undefined) {
                this.operator = data.operator;
            }
            if ("undelete" in data && data.undelete != undefined) {
                this.undelete = data.undelete;
            }
            if ("delete" in data && data.delete != undefined) {
                this.delete = data.delete;
            }
            if ("create" in data && data.create != undefined) {
                this.create = data.create;
            }
            if ("edit" in data && data.edit != undefined) {
                this.edit = data.edit;
            }
            if ("view" in data && data.view != undefined) {
                this.view = data.view;
            }
        }
    }
    get owner() {
        return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
    }
    set owner(value: boolean) {
        pb_1.Message.setField(this, 1, value);
    }
    get master() {
        return pb_1.Message.getFieldWithDefault(this, 2, false) as boolean;
    }
    set master(value: boolean) {
        pb_1.Message.setField(this, 2, value);
    }
    get operator() {
        return pb_1.Message.getFieldWithDefault(this, 3, false) as boolean;
    }
    set operator(value: boolean) {
        pb_1.Message.setField(this, 3, value);
    }
    get undelete() {
        return pb_1.Message.getFieldWithDefault(this, 4, false) as boolean;
    }
    set undelete(value: boolean) {
        pb_1.Message.setField(this, 4, value);
    }
    get delete() {
        return pb_1.Message.getFieldWithDefault(this, 5, false) as boolean;
    }
    set delete(value: boolean) {
        pb_1.Message.setField(this, 5, value);
    }
    get create() {
        return pb_1.Message.getFieldWithDefault(this, 6, false) as boolean;
    }
    set create(value: boolean) {
        pb_1.Message.setField(this, 6, value);
    }
    get edit() {
        return pb_1.Message.getFieldWithDefault(this, 7, false) as boolean;
    }
    set edit(value: boolean) {
        pb_1.Message.setField(this, 7, value);
    }
    get view() {
        return pb_1.Message.getFieldWithDefault(this, 8, false) as boolean;
    }
    set view(value: boolean) {
        pb_1.Message.setField(this, 8, value);
    }
    static fromObject(data: {
        owner?: boolean;
        master?: boolean;
        operator?: boolean;
        undelete?: boolean;
        delete?: boolean;
        create?: boolean;
        edit?: boolean;
        view?: boolean;
    }): RolePermissionMap {
        const message = new RolePermissionMap({});
        if (data.owner != null) {
            message.owner = data.owner;
        }
        if (data.master != null) {
            message.master = data.master;
        }
        if (data.operator != null) {
            message.operator = data.operator;
        }
        if (data.undelete != null) {
            message.undelete = data.undelete;
        }
        if (data.delete != null) {
            message.delete = data.delete;
        }
        if (data.create != null) {
            message.create = data.create;
        }
        if (data.edit != null) {
            message.edit = data.edit;
        }
        if (data.view != null) {
            message.view = data.view;
        }
        return message;
    }
    toObject() {
        const data: {
            owner?: boolean;
            master?: boolean;
            operator?: boolean;
            undelete?: boolean;
            delete?: boolean;
            create?: boolean;
            edit?: boolean;
            view?: boolean;
        } = {};
        if (this.owner != null) {
            data.owner = this.owner;
        }
        if (this.master != null) {
            data.master = this.master;
        }
        if (this.operator != null) {
            data.operator = this.operator;
        }
        if (this.undelete != null) {
            data.undelete = this.undelete;
        }
        if (this.delete != null) {
            data.delete = this.delete;
        }
        if (this.create != null) {
            data.create = this.create;
        }
        if (this.edit != null) {
            data.edit = this.edit;
        }
        if (this.view != null) {
            data.view = this.view;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.owner != false)
            writer.writeBool(1, this.owner);
        if (this.master != false)
            writer.writeBool(2, this.master);
        if (this.operator != false)
            writer.writeBool(3, this.operator);
        if (this.undelete != false)
            writer.writeBool(4, this.undelete);
        if (this.delete != false)
            writer.writeBool(5, this.delete);
        if (this.create != false)
            writer.writeBool(6, this.create);
        if (this.edit != false)
            writer.writeBool(7, this.edit);
        if (this.view != false)
            writer.writeBool(8, this.view);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): RolePermissionMap {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new RolePermissionMap();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.owner = reader.readBool();
                    break;
                case 2:
                    message.master = reader.readBool();
                    break;
                case 3:
                    message.operator = reader.readBool();
                    break;
                case 4:
                    message.undelete = reader.readBool();
                    break;
                case 5:
                    message.delete = reader.readBool();
                    break;
                case 6:
                    message.create = reader.readBool();
                    break;
                case 7:
                    message.edit = reader.readBool();
                    break;
                case 8:
                    message.view = reader.readBool();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): RolePermissionMap {
        return RolePermissionMap.deserialize(bytes);
    }
}
export class OrganizationRoleResource extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        role_id?: string;
        resource?: string;
        permission_map?: RolePermissionMap;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("role_id" in data && data.role_id != undefined) {
                this.role_id = data.role_id;
            }
            if ("resource" in data && data.resource != undefined) {
                this.resource = data.resource;
            }
            if ("permission_map" in data && data.permission_map != undefined) {
                this.permission_map = data.permission_map;
            }
        }
    }
    get role_id() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set role_id(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get resource() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set resource(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get permission_map() {
        return pb_1.Message.getWrapperField(this, RolePermissionMap, 3) as RolePermissionMap;
    }
    set permission_map(value: RolePermissionMap) {
        pb_1.Message.setWrapperField(this, 3, value);
    }
    get has_permission_map() {
        return pb_1.Message.getField(this, 3) != null;
    }
    static fromObject(data: {
        role_id?: string;
        resource?: string;
        permission_map?: ReturnType<typeof RolePermissionMap.prototype.toObject>;
    }): OrganizationRoleResource {
        const message = new OrganizationRoleResource({});
        if (data.role_id != null) {
            message.role_id = data.role_id;
        }
        if (data.resource != null) {
            message.resource = data.resource;
        }
        if (data.permission_map != null) {
            message.permission_map = RolePermissionMap.fromObject(data.permission_map);
        }
        return message;
    }
    toObject() {
        const data: {
            role_id?: string;
            resource?: string;
            permission_map?: ReturnType<typeof RolePermissionMap.prototype.toObject>;
        } = {};
        if (this.role_id != null) {
            data.role_id = this.role_id;
        }
        if (this.resource != null) {
            data.resource = this.resource;
        }
        if (this.permission_map != null) {
            data.permission_map = this.permission_map.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.role_id.length)
            writer.writeString(1, this.role_id);
        if (this.resource.length)
            writer.writeString(2, this.resource);
        if (this.has_permission_map)
            writer.writeMessage(3, this.permission_map, () => this.permission_map.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): OrganizationRoleResource {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new OrganizationRoleResource();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.role_id = reader.readString();
                    break;
                case 2:
                    message.resource = reader.readString();
                    break;
                case 3:
                    reader.readMessage(message.permission_map, () => message.permission_map = RolePermissionMap.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): OrganizationRoleResource {
        return OrganizationRoleResource.deserialize(bytes);
    }
}
export class ApplicationDomain extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        domain?: string;
        validated_on?: number;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("domain" in data && data.domain != undefined) {
                this.domain = data.domain;
            }
            if ("validated_on" in data && data.validated_on != undefined) {
                this.validated_on = data.validated_on;
            }
        }
    }
    get domain() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set domain(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get validated_on() {
        return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
    }
    set validated_on(value: number) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data: {
        domain?: string;
        validated_on?: number;
    }): ApplicationDomain {
        const message = new ApplicationDomain({});
        if (data.domain != null) {
            message.domain = data.domain;
        }
        if (data.validated_on != null) {
            message.validated_on = data.validated_on;
        }
        return message;
    }
    toObject() {
        const data: {
            domain?: string;
            validated_on?: number;
        } = {};
        if (this.domain != null) {
            data.domain = this.domain;
        }
        if (this.validated_on != null) {
            data.validated_on = this.validated_on;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.domain.length)
            writer.writeString(1, this.domain);
        if (this.validated_on != 0)
            writer.writeInt64(2, this.validated_on);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ApplicationDomain {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ApplicationDomain();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.domain = reader.readString();
                    break;
                case 2:
                    message.validated_on = reader.readInt64();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): ApplicationDomain {
        return ApplicationDomain.deserialize(bytes);
    }
}
export class Application extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        id?: string;
        entity?: Entity;
        key?: string;
        return_url?: string;
        domains?: ApplicationDomain[];
        properties?: EntityProperty[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [5, 7], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("id" in data && data.id != undefined) {
                this.id = data.id;
            }
            if ("entity" in data && data.entity != undefined) {
                this.entity = data.entity;
            }
            if ("key" in data && data.key != undefined) {
                this.key = data.key;
            }
            if ("return_url" in data && data.return_url != undefined) {
                this.return_url = data.return_url;
            }
            if ("domains" in data && data.domains != undefined) {
                this.domains = data.domains;
            }
            if ("properties" in data && data.properties != undefined) {
                this.properties = data.properties;
            }
        }
    }
    get id() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set id(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get entity() {
        return pb_1.Message.getWrapperField(this, Entity, 2) as Entity;
    }
    set entity(value: Entity) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get has_entity() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get key() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set key(value: string) {
        pb_1.Message.setField(this, 3, value);
    }
    get return_url() {
        return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
    }
    set return_url(value: string) {
        pb_1.Message.setField(this, 4, value);
    }
    get domains() {
        return pb_1.Message.getRepeatedWrapperField(this, ApplicationDomain, 5) as ApplicationDomain[];
    }
    set domains(value: ApplicationDomain[]) {
        pb_1.Message.setRepeatedWrapperField(this, 5, value);
    }
    get properties() {
        return pb_1.Message.getRepeatedWrapperField(this, EntityProperty, 7) as EntityProperty[];
    }
    set properties(value: EntityProperty[]) {
        pb_1.Message.setRepeatedWrapperField(this, 7, value);
    }
    static fromObject(data: {
        id?: string;
        entity?: ReturnType<typeof Entity.prototype.toObject>;
        key?: string;
        return_url?: string;
        domains?: ReturnType<typeof ApplicationDomain.prototype.toObject>[];
        properties?: ReturnType<typeof EntityProperty.prototype.toObject>[];
    }): Application {
        const message = new Application({});
        if (data.id != null) {
            message.id = data.id;
        }
        if (data.entity != null) {
            message.entity = Entity.fromObject(data.entity);
        }
        if (data.key != null) {
            message.key = data.key;
        }
        if (data.return_url != null) {
            message.return_url = data.return_url;
        }
        if (data.domains != null) {
            message.domains = data.domains.map(item => ApplicationDomain.fromObject(item));
        }
        if (data.properties != null) {
            message.properties = data.properties.map(item => EntityProperty.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: {
            id?: string;
            entity?: ReturnType<typeof Entity.prototype.toObject>;
            key?: string;
            return_url?: string;
            domains?: ReturnType<typeof ApplicationDomain.prototype.toObject>[];
            properties?: ReturnType<typeof EntityProperty.prototype.toObject>[];
        } = {};
        if (this.id != null) {
            data.id = this.id;
        }
        if (this.entity != null) {
            data.entity = this.entity.toObject();
        }
        if (this.key != null) {
            data.key = this.key;
        }
        if (this.return_url != null) {
            data.return_url = this.return_url;
        }
        if (this.domains != null) {
            data.domains = this.domains.map((item: ApplicationDomain) => item.toObject());
        }
        if (this.properties != null) {
            data.properties = this.properties.map((item: EntityProperty) => item.toObject());
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.id.length)
            writer.writeString(1, this.id);
        if (this.has_entity)
            writer.writeMessage(2, this.entity, () => this.entity.serialize(writer));
        if (this.key.length)
            writer.writeString(3, this.key);
        if (this.return_url.length)
            writer.writeString(4, this.return_url);
        if (this.domains.length)
            writer.writeRepeatedMessage(5, this.domains, (item: ApplicationDomain) => item.serialize(writer));
        if (this.properties.length)
            writer.writeRepeatedMessage(7, this.properties, (item: EntityProperty) => item.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Application {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Application();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.id = reader.readString();
                    break;
                case 2:
                    reader.readMessage(message.entity, () => message.entity = Entity.deserialize(reader));
                    break;
                case 3:
                    message.key = reader.readString();
                    break;
                case 4:
                    message.return_url = reader.readString();
                    break;
                case 5:
                    reader.readMessage(message.domains, () => pb_1.Message.addToRepeatedWrapperField(message, 5, ApplicationDomain.deserialize(reader), ApplicationDomain));
                    break;
                case 7:
                    reader.readMessage(message.properties, () => pb_1.Message.addToRepeatedWrapperField(message, 7, EntityProperty.deserialize(reader), EntityProperty));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): Application {
        return Application.deserialize(bytes);
    }
}
export class RolePermissionMapByUserResourcesOrganizationRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        organization?: Organization;
        user?: User;
        role_resources?: OrganizationRoleResource[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [3], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("organization" in data && data.organization != undefined) {
                this.organization = data.organization;
            }
            if ("user" in data && data.user != undefined) {
                this.user = data.user;
            }
            if ("role_resources" in data && data.role_resources != undefined) {
                this.role_resources = data.role_resources;
            }
        }
    }
    get organization() {
        return pb_1.Message.getWrapperField(this, Organization, 1) as Organization;
    }
    set organization(value: Organization) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get has_organization() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get user() {
        return pb_1.Message.getWrapperField(this, User, 2) as User;
    }
    set user(value: User) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get has_user() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get role_resources() {
        return pb_1.Message.getRepeatedWrapperField(this, OrganizationRoleResource, 3) as OrganizationRoleResource[];
    }
    set role_resources(value: OrganizationRoleResource[]) {
        pb_1.Message.setRepeatedWrapperField(this, 3, value);
    }
    static fromObject(data: {
        organization?: ReturnType<typeof Organization.prototype.toObject>;
        user?: ReturnType<typeof User.prototype.toObject>;
        role_resources?: ReturnType<typeof OrganizationRoleResource.prototype.toObject>[];
    }): RolePermissionMapByUserResourcesOrganizationRequest {
        const message = new RolePermissionMapByUserResourcesOrganizationRequest({});
        if (data.organization != null) {
            message.organization = Organization.fromObject(data.organization);
        }
        if (data.user != null) {
            message.user = User.fromObject(data.user);
        }
        if (data.role_resources != null) {
            message.role_resources = data.role_resources.map(item => OrganizationRoleResource.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: {
            organization?: ReturnType<typeof Organization.prototype.toObject>;
            user?: ReturnType<typeof User.prototype.toObject>;
            role_resources?: ReturnType<typeof OrganizationRoleResource.prototype.toObject>[];
        } = {};
        if (this.organization != null) {
            data.organization = this.organization.toObject();
        }
        if (this.user != null) {
            data.user = this.user.toObject();
        }
        if (this.role_resources != null) {
            data.role_resources = this.role_resources.map((item: OrganizationRoleResource) => item.toObject());
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.has_organization)
            writer.writeMessage(1, this.organization, () => this.organization.serialize(writer));
        if (this.has_user)
            writer.writeMessage(2, this.user, () => this.user.serialize(writer));
        if (this.role_resources.length)
            writer.writeRepeatedMessage(3, this.role_resources, (item: OrganizationRoleResource) => item.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): RolePermissionMapByUserResourcesOrganizationRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new RolePermissionMapByUserResourcesOrganizationRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.organization, () => message.organization = Organization.deserialize(reader));
                    break;
                case 2:
                    reader.readMessage(message.user, () => message.user = User.deserialize(reader));
                    break;
                case 3:
                    reader.readMessage(message.role_resources, () => pb_1.Message.addToRepeatedWrapperField(message, 3, OrganizationRoleResource.deserialize(reader), OrganizationRoleResource));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): RolePermissionMapByUserResourcesOrganizationRequest {
        return RolePermissionMapByUserResourcesOrganizationRequest.deserialize(bytes);
    }
}
export class RolePermissionMapByUserResourcesOrganizationResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        role_resources?: OrganizationRoleResource[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("role_resources" in data && data.role_resources != undefined) {
                this.role_resources = data.role_resources;
            }
        }
    }
    get role_resources() {
        return pb_1.Message.getRepeatedWrapperField(this, OrganizationRoleResource, 1) as OrganizationRoleResource[];
    }
    set role_resources(value: OrganizationRoleResource[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    static fromObject(data: {
        role_resources?: ReturnType<typeof OrganizationRoleResource.prototype.toObject>[];
    }): RolePermissionMapByUserResourcesOrganizationResponse {
        const message = new RolePermissionMapByUserResourcesOrganizationResponse({});
        if (data.role_resources != null) {
            message.role_resources = data.role_resources.map(item => OrganizationRoleResource.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: {
            role_resources?: ReturnType<typeof OrganizationRoleResource.prototype.toObject>[];
        } = {};
        if (this.role_resources != null) {
            data.role_resources = this.role_resources.map((item: OrganizationRoleResource) => item.toObject());
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.role_resources.length)
            writer.writeRepeatedMessage(1, this.role_resources, (item: OrganizationRoleResource) => item.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): RolePermissionMapByUserResourcesOrganizationResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new RolePermissionMapByUserResourcesOrganizationResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.role_resources, () => pb_1.Message.addToRepeatedWrapperField(message, 1, OrganizationRoleResource.deserialize(reader), OrganizationRoleResource));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): RolePermissionMapByUserResourcesOrganizationResponse {
        return RolePermissionMapByUserResourcesOrganizationResponse.deserialize(bytes);
    }
}
export class UserInRoleNameRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        organization?: Organization;
        user?: User;
        organization_role?: OrganizationRole;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("organization" in data && data.organization != undefined) {
                this.organization = data.organization;
            }
            if ("user" in data && data.user != undefined) {
                this.user = data.user;
            }
            if ("organization_role" in data && data.organization_role != undefined) {
                this.organization_role = data.organization_role;
            }
        }
    }
    get organization() {
        return pb_1.Message.getWrapperField(this, Organization, 1) as Organization;
    }
    set organization(value: Organization) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get has_organization() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get user() {
        return pb_1.Message.getWrapperField(this, User, 2) as User;
    }
    set user(value: User) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get has_user() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get organization_role() {
        return pb_1.Message.getWrapperField(this, OrganizationRole, 3) as OrganizationRole;
    }
    set organization_role(value: OrganizationRole) {
        pb_1.Message.setWrapperField(this, 3, value);
    }
    get has_organization_role() {
        return pb_1.Message.getField(this, 3) != null;
    }
    static fromObject(data: {
        organization?: ReturnType<typeof Organization.prototype.toObject>;
        user?: ReturnType<typeof User.prototype.toObject>;
        organization_role?: ReturnType<typeof OrganizationRole.prototype.toObject>;
    }): UserInRoleNameRequest {
        const message = new UserInRoleNameRequest({});
        if (data.organization != null) {
            message.organization = Organization.fromObject(data.organization);
        }
        if (data.user != null) {
            message.user = User.fromObject(data.user);
        }
        if (data.organization_role != null) {
            message.organization_role = OrganizationRole.fromObject(data.organization_role);
        }
        return message;
    }
    toObject() {
        const data: {
            organization?: ReturnType<typeof Organization.prototype.toObject>;
            user?: ReturnType<typeof User.prototype.toObject>;
            organization_role?: ReturnType<typeof OrganizationRole.prototype.toObject>;
        } = {};
        if (this.organization != null) {
            data.organization = this.organization.toObject();
        }
        if (this.user != null) {
            data.user = this.user.toObject();
        }
        if (this.organization_role != null) {
            data.organization_role = this.organization_role.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.has_organization)
            writer.writeMessage(1, this.organization, () => this.organization.serialize(writer));
        if (this.has_user)
            writer.writeMessage(2, this.user, () => this.user.serialize(writer));
        if (this.has_organization_role)
            writer.writeMessage(3, this.organization_role, () => this.organization_role.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UserInRoleNameRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UserInRoleNameRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.organization, () => message.organization = Organization.deserialize(reader));
                    break;
                case 2:
                    reader.readMessage(message.user, () => message.user = User.deserialize(reader));
                    break;
                case 3:
                    reader.readMessage(message.organization_role, () => message.organization_role = OrganizationRole.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): UserInRoleNameRequest {
        return UserInRoleNameRequest.deserialize(bytes);
    }
}
export class UserInRoleNameResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        answer?: SimpleAnswer;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("answer" in data && data.answer != undefined) {
                this.answer = data.answer;
            }
        }
    }
    get answer() {
        return pb_1.Message.getFieldWithDefault(this, 1, SimpleAnswer.NO) as SimpleAnswer;
    }
    set answer(value: SimpleAnswer) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        answer?: SimpleAnswer;
    }): UserInRoleNameResponse {
        const message = new UserInRoleNameResponse({});
        if (data.answer != null) {
            message.answer = data.answer;
        }
        return message;
    }
    toObject() {
        const data: {
            answer?: SimpleAnswer;
        } = {};
        if (this.answer != null) {
            data.answer = this.answer;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.answer != SimpleAnswer.NO)
            writer.writeEnum(1, this.answer);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UserInRoleNameResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UserInRoleNameResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.answer = reader.readEnum();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): UserInRoleNameResponse {
        return UserInRoleNameResponse.deserialize(bytes);
    }
}
export class UserRequiresAuthorizationResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        answer?: SimpleAnswer;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("answer" in data && data.answer != undefined) {
                this.answer = data.answer;
            }
        }
    }
    get answer() {
        return pb_1.Message.getFieldWithDefault(this, 1, SimpleAnswer.NO) as SimpleAnswer;
    }
    set answer(value: SimpleAnswer) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        answer?: SimpleAnswer;
    }): UserRequiresAuthorizationResponse {
        const message = new UserRequiresAuthorizationResponse({});
        if (data.answer != null) {
            message.answer = data.answer;
        }
        return message;
    }
    toObject() {
        const data: {
            answer?: SimpleAnswer;
        } = {};
        if (this.answer != null) {
            data.answer = this.answer;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.answer != SimpleAnswer.NO)
            writer.writeEnum(1, this.answer);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UserRequiresAuthorizationResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UserRequiresAuthorizationResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.answer = reader.readEnum();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): UserRequiresAuthorizationResponse {
        return UserRequiresAuthorizationResponse.deserialize(bytes);
    }
}
