import React from "react";
import { createRoot } from "react-dom/client";
import component from "./custom-nav.jsx";

/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */
export function load(node) {
  const root = createRoot(node);
  root.render(React.createElement(component, {}));
}
