/* eslint-disable react/no-unused-state */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from "react";

import MD5 from "crypto-js/md5";

import FeatherIcon from "../../../modules/icons/featherreact";
import context from "../../../context";
import currentUser from "../../../context/CurrentUser";

import Link from "../../../modules/navigation/Link";

import sitetranslations from "../SiteTranslations";
import { NavItem, NavLink, Dropdown } from "react-bootstrap";
import { EnvironmentChangedEvent } from "../../../context/Application";

const appContext = context.application;
const uemitter = currentUser.getEmitter();

let loginUrl = "/auth/login";
let registerUrl = "/auth/register";
let myInformationUrl = "/settings/my_information";
if (window.config.mainsite && window.config.mainsite.urls) {
  const { urls } = window.config.mainsite;
  if (urls.login) {
    loginUrl = urls.login;
  }
  if (urls.register) {
    registerUrl = urls.register;
  }
  if (urls.myinformation) {
    myInformationUrl = urls.myinformation;
  }
}

export default class UserCard extends PureComponent {
  constructor(props) {
    super(props);
    this.onUserDetected = this.onUserDetected.bind(this);
    this.onUserLogOut = this.onUserLogOut.bind(this);
    this.logoutAction = this.logoutAction.bind(this);
    this.updateHash = this.updateHash.bind(this);
    this.updateStateFromEnvironment =
      this.updateStateFromEnvironment.bind(this);
    this.state = {
      isLoggedIn: currentUser.isLoggedIn(),
      environment: appContext.getEnvironment(),
      user: currentUser.getPerson(),
      useLink: false,
      hash: null,
    };
  }

  componentDidMount() {
    console.log("UserCard mounteded");
    const that = this;
    if ("onhashchange" in window) {
      window.addEventListener("hashchange", this.updateHash, false);
    }
    uemitter.on("login", that.onUserDetected);
    uemitter.on("logout", that.onUserLogOut);
    uemitter.on("changeUser", that.onUserDetected);
    appContext.emitter.on(
      EnvironmentChangedEvent,
      this.updateStateFromEnvironment,
    );
  }

  componentWillUnmount() {
    const that = this;
    if ("onhashchange" in window) {
      window.removeEventListener("hashchange", this.updateHash, false);
    }
    uemitter.removeListener("login", that.onUserDetected);
    uemitter.removeListener("logout", that.onUserDetected);
    uemitter.removeListener("changeUser", that.onUserLogOut);
    clearInterval(that.useLinkInterval);
    appContext.emitter.off(
      EnvironmentChangedEvent,
      this.updateStateFromEnvironment,
    );
  }

  onUserDetected() {
    this.setState({
      isLoggedIn: true,
      user: currentUser,
    });
  }

  onUserLogOut() {
    this.setState({
      isLoggedIn: false,
      user: null,
    });
  }
  updateStateFromEnvironment() {
    this.setState({
      environment: appContext.getEnvironment(),
    });
  }

  logoutAction(e) {
    e.preventDefault();
    currentUser
      .logout()
      // eslint-disable-next-line no-unused-vars
      .then((_success) => {
        window.location.reload();
      })
      .catch((logoutError) => {
        console.log(logoutError);
      });
  }

  updateHash() {
    this.setState({
      hash: window.location.hash,
    });
  }

  render() {
    const current_post_path = `service=${
      window.config.scoped.Scope
    }&continue=${encodeURIComponent(window.location.href)}`;

    if (this.state.isLoggedIn) {
      let URL_MyInfo = null;
      if (this.state.environment === "settings") {
        URL_MyInfo = (
          <Dropdown.Item as={Link} href="/settings/my_information">
            <FeatherIcon icon="user" options={{ width: 20, height: 20 }} />{" "}
            {sitetranslations.t("HEADER_ACCOUNT_SETTINGS")}
          </Dropdown.Item>
        );
      } else {
        URL_MyInfo = (
          <Dropdown.Item href={`${myInformationUrl}?${current_post_path}`}>
            <FeatherIcon icon="user" options={{ width: 20, height: 20 }} />{" "}
            {sitetranslations.t("HEADER_ACCOUNT_SETTINGS")}
          </Dropdown.Item>
        );
      }

      // const emailHash = MD5(
      //   currentUser.getPerson().email.toLowerCase()
      // ).toString()

      return (
        <Dropdown as={NavItem}>
          <Dropdown.Toggle as={NavLink} split={false}>
            <span className="avatar avatar-sm">
              <FeatherIcon icon="user" options={{ width: 20, height: 20 }} />
              {/* <img
                crossOrigin
                alt={`Gravatar from  ${currentUser
                  .getPerson()
                  .email.toLowerCase()}`}
                src={`https://www.gravatar.com/avatar/${emailHash}?d=blank`}
              /> */}
            </span>
            <span className="d-none d-xl-block ps-2">
              {sitetranslations.t("HEADER_MY_ACCOUNT")}
            </span>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {URL_MyInfo}
            <Dropdown.Item
              href="#"
              onClick={this.logoutAction}
              title={sitetranslations.t("HEADER_LOGOUT")}
            >
              <FeatherIcon icon="log-out" options={{ width: 20, height: 20 }} />{" "}
              {sitetranslations.t("HEADER_LOGOUT")}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      );
    }
    const URLs = [];
    if (this.state.environment === "authentication") {
      URLs.push(
        <Dropdown.Item key="1" href="/auth/login" as={Link}>
          {sitetranslations.t("HEADER_LOGIN")}
        </Dropdown.Item>,
      );
      URLs.push(
        <Dropdown.Item key="2" href="/auth/register" as={Link}>
          {sitetranslations.t("HEADER_REGISTER")}
        </Dropdown.Item>,
      );
    } else {
      URLs.push(
        <Dropdown.Item key="3" href={`${loginUrl}?${current_post_path}`}>
          {sitetranslations.t("HEADER_LOGIN")}
        </Dropdown.Item>,
      );
      URLs.push(
        <Dropdown.Item key="4" href={`${registerUrl}?${current_post_path}`}>
          {sitetranslations.t("HEADER_REGISTER")}
        </Dropdown.Item>,
      );
    }
    return (
      <>
        <Dropdown as={NavItem}>
          <Dropdown.Toggle as={NavLink} split={false}>
            <span className="avatar avatar-sm">
              <FeatherIcon icon="log-in" options={{ width: 20, height: 20 }} />
            </span>
            <span className="d-none d-xl-block ps-2">
              {sitetranslations.t("HEADER_ACCESS")}
            </span>
          </Dropdown.Toggle>
          <Dropdown.Menu>{URLs}</Dropdown.Menu>
        </Dropdown>
      </>
    );
  }
}
