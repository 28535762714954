import { ajax, AjaxConfig } from "rxjs/ajax";
import { v7 } from "uuid";

export function request<T>(requestData: AjaxConfig) {
  let requestDataC = { ...requestData };
  if (!requestDataC.headers) requestDataC.headers = {};
  requestDataC.headers = Object.assign(requestDataC.headers, {
    "Content-Type": "application/json",
    Accept: "application/json",
    "X-Request-ID": v7(),
  });
  requestDataC.responseType = "json";
  return ajax<T>(requestDataC);
}

export function requestGet<T>(url: string) {
  return request<T>({
    url,
    method: "GET",
  });
}
export function requestPost<T>(url: string, data: unknown) {
  return request<T>({
    url,
    body: data,
    method: "POST",
  });
}
export function requestPut<T>(url: string, data: unknown) {
  return request<T>({
    url,
    body: data,
    method: "PUT",
  });
}
export function requestDelete<T>(url: string, data: unknown) {
  return request<T>({
    url,
    body: data,
    method: "DELETE",
  });
}
