import "./currentScript.polyfill";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import currentUser from "./context/CurrentUser";
require("./modules/error/allErrors");

document.documentElement.className = document.documentElement.className.replace(
  "no-js",
  "js",
);

export default class Registry {
  private registry: { [key: string]: (node: Node) => void } = {};

  public Add(name: string, loader: (node: Node) => void): void {
    this.registry[name] = loader;
  }

  public Execute(name: string, node: Node): void {
    if (this.registry[name]) {
      this.registry[name](node);
    }
  }

  public Exists(name: string): boolean {
    return !!this.registry[name];
  }
}

const registeredComponents = new Registry();

import * as navLoader from "./site/layouts/header.component_loader";

registeredComponents.Add("custom_nav", (node: Node) => {
  navLoader.load(node);
});

function isVisible(elem) {
  return !!(
    elem.offsetWidth ||
    elem.offsetHeight ||
    elem.getClientRects().length
  );
}

function loadFromAttribute() {
  const elementList = document.querySelectorAll("[iz-component]");
  for (let i = 0; i < elementList.length; i += 1) {
    const element = elementList[i];
    const componentName = element.getAttribute("iz-component");
    const loaded = element.getAttribute("_iz_loaded");
    if (
      registeredComponents.Exists(componentName) &&
      loaded !== "true" &&
      isVisible(element)
    ) {
      element.setAttribute("_iz_loaded", "true");
      registeredComponents.Execute(componentName, element);
    }
  }
}

function loadFromTag() {
  const elementList = document.querySelectorAll("iz-component");
  for (let i = 0; i < elementList.length; i += 1) {
    const element = elementList[i];
    const componentName = element.getAttribute("name");
    const loaded = element.getAttribute("_iz_loaded");
    if (
      registeredComponents.Exists(componentName) &&
      loaded !== "true" &&
      isVisible(element)
    ) {
      element.setAttribute("_iz_loaded", "true");
      registeredComponents.Execute(componentName, element);
    }
  }
}
loadFromTag();
loadFromAttribute();

// Returns true if it is a DOM node
function isNode(o) {
  return typeof Node === "object"
    ? o instanceof Node
    : o &&
        typeof o === "object" &&
        typeof o.nodeType === "number" &&
        typeof o.nodeName === "string";
}
window.loadAppOn = (app: string, node: Node) => {
  if (registeredComponents.Exists(app) && isNode(node)) {
    registeredComponents.Execute(app, node);
  } else if (!registeredComponents.Exists(app)) {
    // eslint-disable-next-line no-console
    console.error(`No app ${app}`);
  } else {
    // eslint-disable-next-line no-console
    console.error("Bad node");
  }
};
window.reloadComponents = () => {
  loadFromTag();
  loadFromAttribute();
};
