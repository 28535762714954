// eslint-disable-next-line no-unused-vars
import React from "react";

export default function NotificationsCard() {
  return null;
  /*
    return (
      <div className="dropdown d-none d-md-flex">
        <a className="nav-link icon" data-bs-toggle="dropdown">
          <i className="fe fe-bell" />
          <span className="nav-unread" />
        </a>
        <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
          <a href="#" className="dropdown-item d-flex">
            <span className="avatar mr-3 align-self-center" />
            <div>
              <strong>Nathan</strong> pushed new commit: Fix page load
              performance issue.
              <div className="small text-muted">10 minutes ago</div>
            </div>
          </a>
          <a href="#" className="dropdown-item d-flex">
            <span className="avatar mr-3 align-self-center" />
            <div>
              <strong>Alice</strong> started new task: Tabler UI design.
              <div className="small text-muted">1 hour ago</div>
            </div>
          </a>
          <a href="#" className="dropdown-item d-flex">
            <span className="avatar mr-3 align-self-center" />
            <div>
              <strong>Rose</strong> deployed new version of NodeJS REST Api V3
              <div className="small text-muted">2 hours ago</div>
            </div>
          </a>
          <div className="dropdown-divider" />
          <a href="#" className="dropdown-item text-center">
            Mark all as read
          </a>
        </div>
      </div>
    )
    */
}
