declare global {
  interface Window {
    Globalize: any;
    __showDebugMessages: () => void;
  }
}

/* eslint-disable no-undef */
// Globalize
const inexistentMessages = {};

/**
 * Gets a translation from Globalize
 * @param {string} message
 * @returns {string}
 */
const translationCache = new Map<string, string>();
window["t"] = translationCache;

function ToNestedObject(parts: Array<string>, finaAssign: string): Object {
  const object = {};
  const currentElement = parts.shift();
  if (parts.length >= 1) {
    object[currentElement] = ToNestedObject(parts, finaAssign);
  } else {
    object[currentElement] = finaAssign;
  }
  return object;
}

export function t(
  message,
  variables = { defaultMessage: message } as { defaultMessage?: string },
) {
  try {
    const keys = Object.keys(variables);
    const values = Object.values(variables);
    const key = `${message}_${keys.toString()}_${values.toString()}`;
    if (translationCache.has(key)) {
      return translationCache.get(key);
    }
    const translation = window.Globalize.formatMessage(message, variables);
    if (keys.length === 0) {
      translationCache.set(key, translation);
    }
    return translation;
  } catch (ex) {
    if (inexistentMessages[message]) {
      // Message already exists
    } else {
      console.error(`Translation \`${message}\` not found in Globalize`);
      inexistentMessages[message] = variables.defaultMessage || message;
      if (variables.defaultMessage) {
        try {
          const messageParts = message.split("/");
          const messages = ToNestedObject(
            [window.Globalize.xdata.globalizeLang, ...messageParts],
            variables.defaultMessage,
          );
          window.Globalize.loadMessages(messages);
          const translation = window.Globalize.formatMessage(
            message,
            variables,
          );
          return translation;
        } catch (ex) {
          console.error(ex);
          // omit
        }
      }
    }
    return variables.defaultMessage || message;
  }
}
/**
 *
 * @param {string} path
 * @returns {string}
 */
export function cldrMain(path) {
  return window.Globalize.cldr.main(path);
}

export function getLanguageName(lang) {
  return cldrMain(`localeDisplayNames/languages/${lang}`);
}

export function fetchNamespace(namespace, options) {
  return window.Globalize.fetchNamespace(namespace, options);
}

export function formatDate(date: Date, options) {
  return window.Globalize.formatDate(date, options);
}

export function numberParser(...any) {
  return window.Globalize.numberParser(...any);
}

export function currencyFormatter(...any) {
  return window.Globalize.currencyFormatter(...any);
}

export function numberFormatter(...any) {
  return window.Globalize.numberFormatter(...any);
}

const translation = {
  t,
  cldrMain,
  getLanguageName,
  fetchNamespace,
  formatDate,
  numberParser,
  currencyFormatter,
  numberFormatter,
};
export default translation;

// eslint-disable-next-line no-underscore-dangle
window.__showDebugMessages = () => {
  console.log("Messages", JSON.stringify(inexistentMessages, null, " "));
};
