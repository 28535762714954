export async function fetchJSONRequest<T>(
  url: string,
  requestData: RequestInit = {},
) {
  let requestDataC = { ...requestData };
  if (!requestDataC.headers) requestDataC.headers = {};
  requestDataC.headers = Object.assign(requestDataC.headers, {
    "Content-Type": "application/json",
    Accept: "application/json",
  });
  requestDataC.credentials = "omit";
  return fetch(url, requestDataC).then((response) => {
    return response.json() as T;
  });
}
