import translation from "../../modules/translation";
import { ChangeNotifier } from "../../modules/customstate/ChangeNotifier";

const namespace = "site";

class SiteTranslations extends ChangeNotifier {
  loading = true;

  init() {
    Promise.all([
      translation.fetchNamespace(namespace, { prefixNamespace: true }),
    ])
      .then(() => {
        this.loading = false;
        this.notifyListeners();
      })
      .catch(() => {
        this.loading = false;
        this.notifyListeners();
      });
  }

  t(message, variables = {}) {
    return translation.t(`${namespace}/${message}`, variables);
  }
}

const sitetranslations = new SiteTranslations();
export default sitetranslations;
