declare global {
  interface Window {
    config?: {
      endpoints?: {
        entities?: string;
        translations?: string;
        accounting?: string;
        billing?: string;
      };
    };
    config_assets?: {
      "assets-app"?: string;
      "assets-static"?: string;
    };
    acceptedLanguages?: string[];
    setLanguage?: string;
    browserLanguage?: string;
    contextLanguage?: string;
    uri?: string;
    loadAppOn?(app: string, node: Node): void;
    reloadComponents?(): void;
  }
}

export function getPathAssetsWebpack(): string {
  return window.config_assets["assets-app"] || "/assets/assets";
}

export const translationsEndpointPrefix =
  window.config &&
  window.config.endpoints &&
  window.config.endpoints.translations
    ? window.config.endpoints.translations
    : "/s/translations";

export const entitiesEndpointPrefix =
  window.config && window.config.endpoints && window.config.endpoints.entities
    ? window.config.endpoints.entities
    : "/s/entities";

export const accountingEndpointPrefix =
  window.config && window.config.endpoints && window.config.endpoints.accounting
    ? window.config.endpoints.accounting
    : "/s/accounting";

export const billingEndpointPrefix =
  window.config && window.config.endpoints && window.config.endpoints.billing
    ? window.config.endpoints.billing
    : "/s/billing";

export function getAvailableLanguages(): string[] {
  return window.acceptedLanguages || [];
}

export function getSetLanguage(): string {
  return window.setLanguage;
}

export function getBrowserLanguage(): string {
  return window.browserLanguage;
}

export function getContextLanguage(): string {
  return window.contextLanguage;
}

export function getCurrentUri(): string {
  return window.uri || window.location.pathname;
}
